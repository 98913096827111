.base-widget {
  @apply border border-white  rounded-lg shadow-widget;
}

.lum-widget {
  @apply base-widget bg-white/85 backdrop-blur-md;
}

.lum-widget-sm {
  @apply base-widget bg-white/95 backdrop-blur-sm;
}

.lum-widget-full {
  @apply base-widget bg-white;
}
