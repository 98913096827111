.editor-content {
  a {
    @apply border-b border-current transition duration-200 ease-in-out;

    &:hover {
      @apply border-transparent;
    }
  }

  p {
    @apply mb-3;
  }

  iframe {
    @apply max-w-full;
  }

  > *:first-child {
    @apply mt-0;
  }

  > *:last-child {
    @apply mb-0;
  }

  b,
  strong {
    @apply font-bold;
  }
}
