@use 'variables/_variables.scss';

/* BASE */
@forward 'tailwindcss/base';
@use 'base/_base.scss';
@use 'base/_fonts.scss';
@use 'base/_reset.scss';
@use 'base/_typo.scss';

/* COMPONENTS */
@use 'tailwindcss/components';
@use 'components/_badge.scss';
@use 'components/_container.scss';
@use 'components/_editor-content.scss';
@use 'components/_form.scss';
@use 'components/_header.scss';
@use 'components/_list.scss';
@use 'components/_tooltip.scss';
@use 'components/_widget.scss';

/* UTILITIES */
@use 'tailwindcss/utilities';
