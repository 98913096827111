.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #01194e;
  --mdc-plain-tooltip-supporting-text-color: #99b9ff;
  --mdc-plain-tooltip-supporting-text-font: 'Barlow', sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  --mdc-plain-tooltip-supporting-text-tracking: 0em;
  line-height: 1 !important;
  white-space: pre-line !important;
  text-align: left;

  &-surface {
    padding: 4px 8px 6px 8px !important;
  }

  &.navigation-tooltip {
    left: 7px;
  }
}
