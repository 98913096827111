.typo-h1 {
  @apply text-7xl italic font-semibold;
}

.typo-h2 {
  @apply text-6xl italic font-semibold;
}

.typo-h3 {
  @apply text-5xl italic font-semibold;
}

.typo-h4 {
  @apply text-4xl italic font-semibold;
}

.typo-h5 {
  @apply text-3xl italic font-bold;
}

.typo-h6 {
  @apply text-2xl italic font-bold;
}

.typo-xl {
  @apply text-xl font-semibold;
}

.typo-lg {
  @apply text-lg font-normal;
}

.typo-md {
  @apply text-md font-normal;
}

.typo-sm {
  @apply text-sm font-normal;
}

.typo-xs {
  @apply text-xs font-normal;
}

.typo-label-md {
  @apply text-md font-normal;
}

.typo-label-sm {
  @apply text-sm font-normal;
}

.typo-label-xs {
  @apply text-[12px] font-bold;
}
