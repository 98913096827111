@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/Barlow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/Barlow-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../fonts/Barlow-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../../fonts/Barlow-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../fonts/Barlow-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../../fonts/Barlow-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/Barlow-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/Barlow-BoldItalic.ttf') format('truetype');
}
