@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

$LUM_blue: (
  100: #004fff,
  500: #004fff,
  700: #004fff,
  contrast: (
    100: #004fff,
  ),
);

$lum-primary: mat.m2-define-palette($LUM_blue);
$lum-accent: mat.m2-define-palette($LUM_blue);
$lum-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);

$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Barlow, sans-serif',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lum-theme: mat.m2-define-light-theme(
  (
    typography: $custom-typography,
    density: 0,
    color: (
      primary: $lum-primary,
      accent: $lum-accent,
      warn: $lum-warn,
    ),
  )
);

@include mat.all-component-themes($lum-theme);

:root {
  --mat-sidenav-scrim-color: #01194e29;
}
