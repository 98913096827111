.form-input,
.form-select,
.form-textarea {
  @apply border-0 ring-blue-100 block w-full rounded-lg typo-label-sm bg-blue-100 focus:text-blue-700 hover:text-blue-700 text-blue-700 pl-3 p-2.5 appearance-none placeholder:text-gray-500 text-left ring-[1.5px] focus:ring-blue-500;

  &.focus {
    @apply ring-[1.5px] ring-blue-500;
  }

  &:disabled,
  &.disabled {
    @apply border-0 text-gray-400 ring-offset-0 ring-[1.5px] ring-gray-300 opacity-100 bg-gray-100 pointer-events-none cursor-default;
  }

  &.on-gray {
    @apply bg-white;
  }
}

.form-input,
.form-select {
  @apply h-9;
}

.form-select {
  @apply bg-none;
}

.form-input-lg {
  @apply h-12;
}

.form-textarea {
  @apply py-2.5;
}

input[type='date']::-webkit-date-and-time-value {
  @apply text-left;
}

.form-error:not(:focus) {
  @apply bg-error-100 ring-[1.5px] ring-error-600;
}

select {
  @apply bg-none;
}

.form-checkbox,
.form-radio {
  @apply border-2 #{!important};

  &:checked {
    @apply bg-blue-500 #{!important};
    background-image: none !important;
  }

  &:focus {
    @apply outline-offset-0 outline-0 ring-offset-0 #{!important};
    box-shadow: none;
  }

  &:disabled {
    &:not(:checked) {
      @apply bg-gray-100;
    }
  }
}

.form-checkbox {
  @apply border-blue-500 bg-white rounded-[4px] h-5 w-5;

  &:disabled {
    @apply border-gray-300 #{!important};
    &:checked {
      @apply bg-gray-100 text-gray-300 #{!important};
    }
  }
}

.form-radio {
  @apply ring-2 ring-blue-400 border-white w-4 h-4;

  &:checked,
  &:focus {
    @apply ring-2 bg-blue-500 border-white  ring-blue-500 #{!important};
  }

  &:disabled {
    @apply border-gray-100  ring-gray-300 #{!important};
    &:checked {
      @apply bg-gray-300 text-gray-300 #{!important};
    }
  }
}

label {
  a {
    @apply underline;
  }
}
