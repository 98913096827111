html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

@layer base {
  html {
    font-family: 'Barlow', 'Arial', 'sans-serif';
    @apply scroll-smooth text-blue-700;
  }
}

svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

button {
  letter-spacing: inherit;
}

b,
strong {
  @apply font-bold;
}

.h-minus-header-footer {
  height: calc(100vh - 52px - 72px - 16px - 16px);
}

a:not(.main-navigation a) {
  &:hover {
    text-decoration: underline;
  }
}
